.swiper-pagination{
    /* @apply pt-3 mt-3 */
}
.swiper-pagination-bullet{
    opacity: 1;
     @apply bg-white w-[10px] rounded-full h-[10px]
}
.swiper-pagination-bullet-active{
    @apply bg-[#1C979F] w-[50px] rounded-lg h-[10px]
}
.swiper-slide{
    @apply w-[100%] bg-[#1C979F] rounded-xl flex justify-center h-[22rem] md:h-[26.25rem] 
}
.swiper-wrapper{
    @apply pb-4 md:pb-0
}
.swiper {
    @apply w-[100%] p-6 md:p-10  basis-2/5
}

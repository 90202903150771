
body {
  margin: 0;
    font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* white-space: nowrap; */
}

.active{
  font-weight: 500 !important;
  background: rgb(237, 231, 246);
  color: rgb(94, 53, 177) !important;
}

.button__bg {
  background: rgb(149 78 255);
  color: white;
}

.card__header{
  background-color: #F0F3F5;
  flex-wrap: wrap;
  gap: 7px;
}

.content__body{
  /* background-color: rgb(227, 242, 253); */
    width: 100%;
    /* min-height: calc(100vh - 88px); */
    min-height: 100%;
    flex-grow: 1;
    padding: 20px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 8px;
    transition: margin 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}



.light__bg{
  background: rgb(237, 231, 246);
  color: rgb(94, 53, 177);
}


.purple_text{
  color: rgb(94, 53, 177);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form__btn__bg{
  background: rgb(94, 53, 177);
}

.btn-bg{
  background: rgb(94, 53, 177);
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* react quill editor height */
.ql-editor {
  min-height: 200px;
}

ul.pagination {
  display: flex;
}





.form__center {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34%;
}


.popup__background {
  background: #00000069;
  padding: wid;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  margin-left: -300px;
  z-index: 1000;
}


@media only screen and (max-width: 768px) {

  .form__center {
    top: 20%;
    left: 24%;
    transform: translate(-20%, -20%);
    width: 84%;
  }

  .popup__background {
    margin-left: -20px;
  }

}

button.btn-bg {
  background: rgb(94, 53, 177);
  color: white;
}
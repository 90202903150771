.card-bg-image {
    /* background-color: red; */
    width: 100%;
    min-height: 500px;
    /* min-width: 400px; */
    background-image: url("./../../../../public/img/v2/card-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size:contain;
}

@media only screen and (max-width: 600px) {
    .card-bg-image {
        background-size: cover;
        min-height: 200px;
    }
}
.sidebar{
  width: 20rem;
  /* transition: width 400ms; */
  opacity: 1;
  transition: width 400ms ease, opacity 1.5s ease; 
  height: 90vh;
  z-index: 10;
  /* overflow-y: scroll; */
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}
.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.toggle__Sidebar{
  width: 0rem;
  transition: width 400ms;
  margin-top:50px; 
  
  opacity: 0;
}

.menu_link{
    padding: 10px 10px;
    color: rgb(97, 97, 97);;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    align-items: center;    
  }
  
 
  
  .menu__icon{
    font-size: 21px;
    margin-top: -11px;
  }

  .menu_link:hover{
    font-weight: 500;
    background: rgb(237, 231, 246);
    color: rgb(94, 53, 177) ;
  }


  @media only screen and (max-width: 769px)  {

    .sidebar{
      width: 15rem;
    }

    .sidebar{
       position: absolute;
       top: -20px;
       left: 0;
    }

    .sidebar{
      margin-left: -300px;
      transition: margin-left 400ms; 
    }

    .toggle__phone__Sidebar{
      margin-left: 0px;
      transition: margin-left 400ms; 
    }

  }

 
